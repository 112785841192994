<template>
  <div class="HeaderMenuItem d-none d-lg-flex justify-content-center">
    <div :class="{'HeaderMenuItem-item': currentPage }" class="d-flex justify-content-center">
      <img
        class="HeaderMenuItem-image"
        :alt="title"
        :src="require('@/assets/icons/' + icon)"
      >
      <span class="text-l ml-1 HeaderMenuItem-link" @click="onclickHeaderMenuItem">
        {{ title }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderMenuItem',
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    absoluteRoute: {
      type: Boolean,
      default: false
    },
    openInNewTab: {
      type: Boolean,
      default: false
    },
    currentPage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  },
  methods: {
    onclickHeaderMenuItem () {
      if (this.$route.name === this.routeName) {
        return
      }

      var wName = '_self'
      if (this.openInNewTab) {
        wName = '_blank'
      }

      if (this.absoluteRoute) {
        window.open(this.routeName, wName)
        return
      }

      this.$router.push({ name: this.routeName })
    }
  }
}
</script>

<style lang="less">
@import "headerMenuItem";
</style>
