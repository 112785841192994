<template>
<b-container id="header" fluid class="header">
  <b-container fluid="lg" class="py-3">
    <b-row class="align-items-center">
      <b-col cols="2">
        <Logo />
      </b-col>
      <b-col cols="7">
        <b-row class="menu-header d-flex justify-content-start">
          <b-col>
            <HeaderMenuItem
              title="Proyectos"
              icon="pocket.svg"
              route-name="project-list"
              :current-page="currentRouteName === 'project-list'"
           />
          </b-col>
          <b-col v-if="isProfileTechnician">
            <HeaderMenuItem
              title="Mis bookings"
              icon="booking.svg"
              route-name="positions-inbox"
              :current-page="currentRouteName.indexOf('positions-') >= 0"
           />
          </b-col>
          <!-- <b-col v-if="isProfileBusiness">
            <HeaderMenuItem
              v-if="isBookingCreator"
              title="Mis bookings"
              icon="booking.svg"
              route-name="bookings-opened"
              :current-page="currentRouteName.indexOf('bookings-') >= 0"
           />
          </b-col> -->
          <b-col v-if="isProfileTechnician">
            <HeaderMenuItem
              title="Mi perfil"
              icon="user.svg"
              route-name="dashboard"
              :current-page="currentRouteName === 'dashboard-business' || currentRouteName === 'dashboard-technician'"
            />
          </b-col>
          <b-col>
            <HeaderMenuItem
              title="Directorio"
              icon="icon-directory.svg"
              route-name="community"
              :current-page="currentRouteName === 'community' || currentRouteName === 'private-technician-profile'"
            />
          </b-col>
          <b-col>
            <HeaderMenuItem
              title="Comunidad"
              icon="icon-community.svg"
              absolute-route
              open-in-new-tab
              route-name="https://comunidad.avify.net/oauth2/callback"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="3">
        <UserMenu
          :user="user"
          :configUrl="configUrl"
          :currentRouteName="currentRouteName"
        />
      </b-col>
    </b-row>
  </b-container>
</b-container>
</template>

<script>
import Logo from '@/components/Header/Logo/Logo.vue'
import UserMenu from '@/components/Header/UserMenu/UserMenu.vue'
import HeaderMenuItem from './HeaderMenuItem/HeaderMenuItem'
import Profiles from '@/utils/getProfiles.js'
export default {
  name: 'Header',
  props: {
    title: String,
    currentRouteName: String,
    configUrl: String,
    user: Object
  },
  computed: {
    isProfileTechnician () {
      return this.$store.state.currentProfile === Profiles.PROFILE_TECHNICIAN
    },
    isProfileBusiness () {
      return this.$store.state.currentProfile === Profiles.PROFILE_BUSINESS
    },
    loggedUser () {
      return this.$store.getters.loggedUser
    },
    isBookingCreator () {
      return this.loggedUser.getBusiness.isBookingCreator
    }
  },
  components: {
    HeaderMenuItem,
    Logo,
    UserMenu
  }
}
</script>

<style lang="less">
  @import 'header.less';
  #header {
    .col {
      &:last-of-type {
        padding-right: 0;
      }
      &:first-of-type {
        padding-left: 0;
      }
      span {
        white-space: nowrap;
      }
    }
  }
</style>
