<template>
  <div>
    <div id="" class="d-flex justify-content-end align-items-center">
      <div class="UserMenu-iconBlock d-none d-lg-block">
        <Avatar
          :connectedBadge=true
          classes="UserMenu-avatar"
          :path="userAvatarPath"
        />
      </div>
      <div>
        <b-dropdown
          id="UserMenu-dropdown"
          :text=userMenuTitle
          class="UserMenu-email ml-3 d-none d-lg-block" no-caret
        >
          <b-dropdown-item
            :to="{ name: 'public-user-profile', params: { slug: userSlug }}"
            v-if="isProfileTechnician"
            target="_blank">
              Ver perfil público
          </b-dropdown-item>
          <b-dropdown-divider
            v-if="isProfileTechnician"
          />
          <b-dropdown-item
            v-if="isProfileTechnician"
            :to="{ name: 'config-user-account'}">
            Configuración
          </b-dropdown-item>
          <b-dropdown-item
            v-if="isProfileBusiness"
            :to="{ name: 'config-user-business-account'}">
            <img
              class="HeaderMenuItem-image"
              :src="require('@/assets/icons/settings.svg')"
            >
            Configuración
          </b-dropdown-item>
          <b-dropdown-item
            v-if="isProfileBusiness"
            :to="{ name: 'employee-list'}">
            <img
              class="HeaderMenuItem-image"
              :src="require('@/assets/icons/icon-users.svg')"
            >
            Equipo
          </b-dropdown-item>
          <b-dropdown-item
            v-if="isProfileBusiness"
            :to="{ name: 'hardware-list'}">
            <img
              class="HeaderMenuItem-image"
              :src="require('@/assets/icons/speaker.svg')"
            >
            Hardware
          </b-dropdown-item>
          <b-dropdown-divider
            v-if="isProfileTechnician && user.getBusiness"
          />
          <b-dropdown-item
            @click="switchToBusiness"
            v-if="isProfileTechnician && user.getBusiness"
          >
            Usar Avify como {{ user.getBusiness.name }}
          </b-dropdown-item>
          <b-dropdown-divider
            v-if="user.getTechnician && isProfileBusiness"
          />
          <b-dropdown-item
            @click="switchToTechnician"
            v-if="isProfileBusiness && user.getTechnician"
          >
            Usar Avify como {{ user.firstName || user.email }}
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item :to="{ name: 'logout'}">Cerrar sesión</b-dropdown-item>
          <b-dropdown-divider
            v-if="!user.getBusiness || !user.getTechnician"
          />
          <b-dropdown-item
            class="underlined"
            :to="{name: 'complete-business'}"
            v-if="!user.getBusiness"
          >
            Crear un perfil como empresa
          </b-dropdown-item>
          <b-dropdown-item
            class="underlined"
            :to="{name: 'complete-technician'}"
            v-if="!user.getTechnician">
            Crear un perfil como técnico
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <b-button v-b-toggle.sidebar-menu class="d-block d-lg-none UserMenu-sidebarToggle">
      <Avatar
        :menuBadge=true
        classes="UserMenu-avatar"
        :path="userAvatarPath"
      />
    </b-button>
    <b-sidebar
      id="sidebar-menu"
      :title="userName"
      right
      no-header
      sidebar-class="UserMenu-sidebar d-lg-none"
      body-class="UserMenu-sidebar-body"
      text-variant="light"
      backdrop
    >
     <template v-slot:default="{ hide }">
        <div>
          <b-button class="close" @click="hide">
            <span aria-hidden="true">&times;</span>
          </b-button>
          <Avatar
            classes="UserMenu-avatar"
            :path="userAvatarPath"
          />
          <h6>{{ userName }}</h6>
        </div>
        <div>
          <ul>
            <li v-if="isProfileTechnician">
              <router-link
                :to="{ name: 'dashboard-technician'}"
              >
                Mi perfil
              </router-link>
            </li>
            <li v-if="isProfileTechnician">
              <router-link :to="{ name: 'project-list' }">Proyectos</router-link>
            </li>
            <li v-if="isProfileTechnician">
              <router-link :to="{ name: 'positions-inbox' }">Mis bookings</router-link>
            </li>
            <li v-if="isProfileBusiness && isBookingCreator">
              <router-link :to="{ name: 'project-list' }">Proyectos</router-link>
            </li>
            <li v-if="isProfileBusiness && isBookingCreator">
              <router-link :to="{ name: 'employee-list' }">Equipo</router-link>
            </li>
            <li v-if="isProfileBusiness && isBookingCreator">
              <router-link :to="{ name: 'hardware-list' }">Hardware</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'community' }">Directorio</router-link>
            </li>
            <li>
              <b-link
                target="_blank"
                href="https://comunidad.avify.net/oauth2/callback">Comunidad</b-link>
            </li>
            <li v-if="isProfileTechnician">
              <router-link :to="{ name: 'public-user-profile', params: { slug: userSlug }}" target="_blank">Ver perfil público</router-link>
            </li>

            <li v-if="!user.getBusiness">
              <router-link :to="{name: 'complete-business'}">
                Crear un perfil como empresa
              </router-link>
            </li>
            <li v-if="!user.getTechnician">
              <router-link :to="{name: 'complete-technician'}">
                Crear un perfil como técnico
              </router-link>
            </li>
            <li v-if="isProfileTechnician">
              <span
                v-if="user.getBusiness"
                @click="switchToBusiness"
              >
                Usar Avify como {{ user.getBusiness.name }}
              </span>
            </li>
            <li v-if="isProfileBusiness">
              <span
                v-if="user.getTechnician"
                @click="switchToTechnician"
              >
                Usar Avify como {{ user.firstName }}
              </span>
            </li>

            <li>
              <router-link
                v-if="isProfileTechnician"
                :to="{ name: 'config-user-account'}">
                Configuración
              </router-link>
              <router-link
                v-if="isProfileBusiness"
                :to="{ name: 'config-user-business-account'}">
                Configuración
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'logout'}">Cerrar sesión</router-link>
            </li>
          </ul>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store'
import Profiles from '@/utils/getProfiles'
import Avatar from '@/components/Header/Avatar/Avatar.vue'

export default {
  name: 'UserMenu',
  props: {
    user: {
      type: Object,
      required: true
    },
    currentRouteName: {
      type: String,
      required: true
    }
  },
  methods: {
    switchToBusiness () {
      this.$store.commit('setCurrentProfileBusiness')
      this.$router.push({ name: 'community' })
    },
    switchToTechnician () {
      this.$store.commit('setCurrentProfileTechnician')
      this.$router.push({ name: 'dashboard-technician' })
    }
  },
  computed: {
    loggedUser () {
      return store.state.loggedUser
    },
    isProfileTechnician () {
      return this.$store.state.currentProfile === Profiles.PROFILE_TECHNICIAN
    },
    isProfileBusiness () {
      return this.$store.state.currentProfile === Profiles.PROFILE_BUSINESS
    },
    userMenuTitle () {
      if (this.isProfileBusiness) {
        return this.loggedUser.getBusiness.name
      }
      return this.loggedUser.email
    },
    userName () {
      return this.getCompleteName
    },
    userSlug () {
      return this.loggedUser.slug
    },
    userAvatarPath () {
      if (this.isProfileBusiness) {
        if (this.loggedUser.getBusiness && this.loggedUser.getBusiness.avatar === null) {
          return null
        }
        return process.env.VUE_APP_IMAGE_API_URL + this.loggedUser.getBusiness.avatar.path
      }
      if (this.loggedUser.getTechnician && this.loggedUser.getTechnician.avatar) {
        return process.env.VUE_APP_IMAGE_API_URL + this.loggedUser.getTechnician.avatar.path
      }

      return null
    },
    isBookingCreator () {
      return this.loggedUser.getBusiness.isBookingCreator
    }
  },
  components: {
    Avatar
  }
}
</script>

<style lang="less">
  @import 'userMenu.less';
  .underlined {
    a {
      text-decoration: underline;
    }
  }
  .dropdown-item {
    overflow: hidden;
  }
</style>
