<template>
<div>
  <img v-if=path
    :alt="altText"
    :src="path"
    :class="classes"
  >

  <img v-else
    :alt="altText"
    src="../../../assets/icons/icon-empty-avatar-header.svg"
    :class="classes"
  >
  <span v-if="connectedBadge" class="UserMenu-userConnectedIndicator d-none d-sm-block" />
  <span v-if="menuBadge" class="UserMenu-menuIndicator" />
</div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    path: {
      type: String
    },
    classes: String,
    altText: String,
    connectedBadge: {
      type: Boolean,
      required: false,
      default: false
    },
    menuBadge: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
<style lang="less">
  // @import 'avatar.less';
</style>
